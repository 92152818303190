import React, { useState, useCallback, useEffect } from 'react';
import { Button, TextField, Typography, Box, Slider, Paper, Select, MenuItem, Chip } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const apiBaseUrl = process.env.REACT_APP_API_URL || '';

export default function RadioSettings() {
  const [volume, setVolume] = useState(50);
  const [radioState, setRadioState] = useState('idle');
  const [isRadioPlaying, setIsRadioPlaying] = useState(false);
  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState(() => {
    const saved = localStorage.getItem('selectedCalendars');
    return saved ? JSON.parse(saved) : ['primary'];
  });
  const [needsReauth, setNeedsReauth] = useState(false);
  const [isSpotifyConnected, setIsSpotifyConnected] = useState(false);
  const [talkBreakFrequency, setTalkBreakFrequency] = useState(15); // in minutes
  const [contentPreferences, setContentPreferences] = useState({
    news: true,
    weather: true,
    traffic: false,
    entertainment: true
  });

  useEffect(() => {
    // Check Google Calendar connection
    fetch(`${apiBaseUrl}/api/calendar-list`)
      .then(response => {
        if (!response.ok) {
          if (response.status === 401) {
            return response.json().then(data => {
              if (data.needsReauth) {
                setNeedsReauth(true);
              }
              throw new Error('Authentication required');
            });
          }
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCalendars(data);
        setIsGoogleCalendarConnected(true);
      })
      .catch(error => {
        console.error('Error fetching calendars:', error);
        setIsGoogleCalendarConnected(false);
      });

    // Check if Spotify is connected
    fetch(`${apiBaseUrl}/api/spotify-token`)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data.access_token) {
          setIsSpotifyConnected(true);
          console.log('Spotify token retrieved successfully');
        } else {
          setIsSpotifyConnected(false);
          console.log('No Spotify token available');
        }
      })
      .catch(error => {
        console.error('Error checking Spotify connection:', error);
        setIsSpotifyConnected(false);
      });

    // Load radio settings
    fetch(`${apiBaseUrl}/api/radio-settings`)
      .then(response => response.json())
      .then(data => {
        setVolume(data.volume || 50);
        setTalkBreakFrequency(data.talkBreakFrequency || 15);
        setContentPreferences(data.contentPreferences || {
          news: true,
          weather: true,
          traffic: false,
          entertainment: true
        });
      })
      .catch(error => {
        console.error('Error loading radio settings:', error);
      });

    // Check radio status
    const checkRadioStatus = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/api/radio-status`);
        if (response.ok) {
          const data = await response.json();
          setIsRadioPlaying(data.isRadioCurrentlyPlaying);
        }
      } catch (error) {
        console.error('Error checking radio status:', error);
      }
    };

    checkRadioStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedCalendars', JSON.stringify(selectedCalendars));
  }, [selectedCalendars]);

  const handleGoogleCalendarAuth = () => {
    window.location.href = `${apiBaseUrl}/api/auth/google`;
  };

  const handleSpotifyLogin = () => {
    window.location.href = `${apiBaseUrl}/login-spotify`;
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
    updateRadioSettings({ volume: newValue });
  };

  const handleTalkBreakFrequencyChange = (event, newValue) => {
    setTalkBreakFrequency(newValue);
    updateRadioSettings({ talkBreakFrequency: newValue });
  };

  const handleContentPreferenceChange = (preference) => {
    setContentPreferences(prev => {
      const newPreferences = { ...prev, [preference]: !prev[preference] };
      updateRadioSettings({ contentPreferences: newPreferences });
      return newPreferences;
    });
  };

  const updateRadioSettings = useCallback((settings) => {
    fetch(`${apiBaseUrl}/api/update-radio-settings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(settings)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Radio settings updated:', data);
      })
      .catch(error => {
        console.error('Error updating radio settings:', error);
      });
  }, []);

  const handleToggleRadio = useCallback(async () => {
    try {
      const endpoint = isRadioPlaying ? 'stop-radio' : 'start-radio';
      const response = await fetch(`${apiBaseUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ volume, calendars: selectedCalendars })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorData.message || 'Unknown error'}`);
      }

      const data = await response.json();
      console.log(`${endpoint} response:`, data);
      setIsRadioPlaying(!isRadioPlaying);
    } catch (error) {
      console.error(`Error toggling radio:`, error);
      alert(`Error toggling radio: ${error.message}`);
    }
  }, [isRadioPlaying, volume, selectedCalendars]);

  return (
    <Box sx={{ 
      maxWidth: 400, 
      margin: 'auto', 
      padding: 2,
      backgroundColor: 'black',
      color: 'grey',
    }}>
      <Typography variant="h4" gutterBottom>Radio Settings</Typography>

      <Box sx={{ mt: 2 }}>
        <Button 
          variant="contained" 
          color={isRadioPlaying ? "secondary" : "primary"}
          onClick={handleToggleRadio}
          fullWidth
        >
          {isRadioPlaying ? 'Stop Radio' : 'Start Radio'}
        </Button>
      </Box>

      <Typography id="volume-slider" gutterBottom sx={{ mt: 2 }}>
        Volume
      </Typography>
      <Slider
        value={volume}
        onChange={handleVolumeChange}
        aria-labelledby="volume-slider"
        valueLabelDisplay="auto"
        step={10}
        marks
        min={0}
        max={100}
        sx={{ color: 'grey' }}
      />

      <Typography id="talk-break-slider" gutterBottom sx={{ mt: 2 }}>
        Talk Break Frequency (minutes)
      </Typography>
      <Slider
        value={talkBreakFrequency}
        onChange={handleTalkBreakFrequencyChange}
        aria-labelledby="talk-break-slider"
        valueLabelDisplay="auto"
        step={5}
        marks
        min={5}
        max={60}
        sx={{ color: 'grey' }}
      />

      <Paper elevation={3} sx={{ mt: 2, p: 2, backgroundColor: '#1a1a1a', color: 'grey' }}>
        <Typography variant="h6" gutterBottom>Content Preferences</Typography>
        {Object.entries(contentPreferences).map(([key, value]) => (
          <Button
            key={key}
            variant={value ? "contained" : "outlined"}
            color="primary"
            onClick={() => handleContentPreferenceChange(key)}
            sx={{ m: 0.5 }}
          >
            {key}
          </Button>
        ))}
      </Paper>

      <Box sx={{ mt: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSpotifyLogin}
          fullWidth
          disabled={isSpotifyConnected}
        >
          {isSpotifyConnected ? 'Spotify Connected' : 'Connect Spotify'}
        </Button>
      </Box>

      <Box sx={{ mt: 2 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleGoogleCalendarAuth}
          fullWidth
          disabled={isGoogleCalendarConnected && !needsReauth}
        >
          {isGoogleCalendarConnected ? (needsReauth ? 'Reconnect Google Calendar' : 'Google Calendar Connected') : 'Connect Google Calendar'}
        </Button>
      </Box>

      {needsReauth && (
        <Typography color="error" sx={{ mt: 2 }}>
          Your Google Calendar connection needs to be renewed. Please click the button above to reconnect.
        </Typography>
      )}

      {isGoogleCalendarConnected && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Select Calendars:
          </Typography>
          <Select
            multiple
            value={selectedCalendars}
            onChange={(event) => setSelectedCalendars(event.target.value)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={calendars.find(cal => cal.id === value)?.summary || value} />
                ))}
              </Box>
            )}
            sx={{ width: '100%', color: 'grey', '& .MuiOutlinedInput-notchedOutline': { borderColor: 'grey' } }}
          >
            {calendars.map((calendar) => (
              <MenuItem key={calendar.id} value={calendar.id}>
                {calendar.summary}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
}