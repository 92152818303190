import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';

const track = {
  name: "",
  album: {
    images: [{ url: "" }]
  },
  artists: [{ name: "" }]
};

function WebPlayback(props) {
  const [player, setPlayer] = useState(undefined);
  const [is_paused, setPaused] = useState(false);
  const [is_active, setActive] = useState(false);
  const [current_track, setTrack] = useState(track);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    window.onSpotifyWebPlaybackSDKReady = () => {
      const player = new window.Spotify.Player({
        name: 'RadiYou Web Player',
        getOAuthToken: cb => { cb(props.token); },
        volume: 0.5
      });
  
      setPlayer(player);
  
      player.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        // Here, you should trigger playback to this device
        transferPlayback(device_id);
      });
  
      player.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
      });
  
      player.connect().then(success => {
        if (success) {
          console.log('The Web Playback SDK successfully connected to Spotify!');
        }
      });
    };
  }, [props.token]);
  
  // Add this function to handle playback transfer
  const transferPlayback = async (deviceId) => {
    try {
      await fetch('https://api.spotify.com/v1/me/player', {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${props.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_ids: [deviceId],
          play: true,
        }),
      });
      console.log('Playback transferred to web player');
    } catch (error) {
      console.error('Error transferring playback:', error);
    }
  };
  

  if (!is_active) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">
          Instance not active. Transfer your playback using your Spotify app
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ textAlign: 'center', mt: 4 }}>
      <Box sx={{ mb: 4 }}>
        <img src={current_track.album.images[0].url} alt="Album cover" style={{ width: '300px', height: '300px' }} />
      </Box>
      <Typography variant="h5" gutterBottom>
        {current_track.name}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {current_track.artists[0].name}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => { player.previousTrack() }} 
          sx={{ mr: 2 }}
        >
          &lt;&lt;
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => { player.togglePlay() }}
          sx={{ mr: 2 }}
        >
          { is_paused ? "PLAY" : "PAUSE" }
        </Button>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => { player.nextTrack() }}
        >
          &gt;&gt;
        </Button>
      </Box>
    </Box>
  );
}

function RadioDJ() {
  const [token, setToken] = useState('');

  useEffect(() => {
    async function getToken() {
      const response = await fetch('/radiyou/auth/token');
      const json = await response.json();
      setToken(json.access_token);
    }

    getToken();
  }, []);

  return (
    <>
      {token === '' ? 
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
        : <WebPlayback token={token} />
      }
    </>
  );
}

export default RadioDJ;